











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemSIU131_Task18',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer1: null,
        studentAnswer2: null,
        studentAnswer3: null,
      },
      questions: [
        {
          text: 'What is the order of the reaction in benzyl iodide, ',
          latexContent: 'C6H5CH2I?',
        },
        {
          text: 'What is the order of the reaction in ethoxide, ',
          latexContent: 'C2H5O^-?',
        },
        {
          text: 'What is the overall reaction order?',
          latexContent: '',
        },
      ],
      options: [
        {
          text: '$0^\\text{th}$',
          value: '0',
        },
        {
          text: '$1^\\text{st}$',
          value: '1',
        },
        {
          text: '$2^\\text{nd}$',
          value: '2',
        },
        {
          text: '$3^\\text{rd}$',
          value: '3',
        },
        {
          text: '$4^\\text{th}$',
          value: '4',
        },
        {
          text: '$5^\\text{th}$',
          value: '5',
        },
      ],
    };
  },
};
